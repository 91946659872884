import { Typography } from 'antd';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center'}}> {/* Style added for demonstration; adjust as needed */}
            <Typography.Text>
                404 Not Found
            </Typography.Text>
        </div>
    );
}

export default NotFound;