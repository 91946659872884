import { CanvasStarterData } from "./types/CanvasInterfaces";

export const CanvasStarters: CanvasStarterData[] = [
    {
        "name": "Service Estimate",
        "canvasDesign": {
            "Shapes": [
                {
                    "id": "iycU",
                    "type": "Heading",
                    "x": 539,
                    "y": 55,
                    "rotation": 0,
                    "textValue": "LICENSE #XXXXXX",
                    "fontSize": 28,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "y3NL",
                    "type": "Heading",
                    "x": 375,
                    "y": 107,
                    "rotation": 0,
                    "textValue": "Client",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "VNLq",
                    "type": "ShortTextInput",
                    "name": "Client Name",
                    "x": 445,
                    "y": 107,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "John Doe",
                    "horizontalAlign": "left",
                    "width": 331,
                    "height": 20,
                },
                {
                    "id": "X6FS",
                    "type": "Heading",
                    "x": 354,
                    "y": 137,
                    "rotation": 0,
                    "textValue": "Address",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "wUD6",
                    "type": "ShortTextInput",
                    "name": "Client Address",
                    "x": 445,
                    "y": 137,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "1234 W 1st St Los Angeles, CA",
                    "horizontalAlign": "left",
                    "width": 332,
                    "height": 20,
                },
                {
                    "id": "XsXu",
                    "type": "Heading",
                    "x": 369,
                    "y": 167,
                    "rotation": 0,
                    "textValue": "Phone",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "GutW",
                    "type": "PhoneInput",
                    "name": "Phone Number",
                    "x": 446,
                    "y": 166,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "(555)555-5555",
                    "horizontalAlign": "left",
                    "width": 160,
                    "height": 20,
                },
                {
                    "id": "TFIW",
                    "type": "EmailInput",
                    "name": "Email Address",
                    "x": 446,
                    "y": 196,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "john.doe@email.com",
                    "horizontalAlign": "left",
                    "width": 334,
                    "height": 20,
                },
                {
                    "id": "QAI8",
                    "type": "Heading",
                    "x": 376,
                    "y": 196,
                    "rotation": 0,
                    "textValue": "Email",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "PhkT",
                    "type": "Heading",
                    "x": 25,
                    "y": 137,
                    "rotation": 0,
                    "textValue": "Licensed Bonded Insured",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": ""
                },
                {
                    "id": "kBEX",
                    "type": "Heading",
                    "x": 23,
                    "y": 166,
                    "rotation": 0,
                    "textValue": "(555)555-5555",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "cEAh",
                    "type": "Heading",
                    "x": 25,
                    "y": 196,
                    "rotation": 0,
                    "textValue": "johndoe@mybizemail.com",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "aMrS",
                    "type": "Heading",
                    "x": 28,
                    "y": 860,
                    "rotation": 0,
                    "textValue": "Notes:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "Cdz2",
                    "type": "LongTextInput",
                    "name": "Notes",
                    "x": 29,
                    "y": 893,
                    "rotation": 0,
                    "verticalAlign": "top",
                    "fontSize": 18,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "",
                    "horizontalAlign": "left",
                    "width": 754,
                    "height": 122,
                },
                {
                    "id": "XzPU",
                    "type": "Heading",
                    "x": 23,
                    "y": 244,
                    "rotation": 0,
                    "textValue": "Service Estimate",
                    "fontSize": 28,
                    "fill": "#848484",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "3F80",
                    "type": "Rectangle",
                    "x": 13,
                    "y": 278,
                    "rotation": 0,
                    "width": 786,
                    "height": 6,
                    "fill": "#bfbfbf",
                    "stroke": "",
                    "strokeWidth": 1
                },
                {
                    "id": "aULg",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 25,
                    "y": 293,
                    "rotation": 0,
                    "border": undefined,
                    "rows": [
                        [
                            {
                                "id": "1jFr",
                                "x": 100,
                                "y": 100,
                                "width": 601,
                                "height": 31,
                                "verticalAlign": "middle",
                                "horizontalAlign": "left",
                                "type": "TableCell",
                                "content": {
                                    "id": "1jFr",
                                    "type": "TextCell",
                                    "x": 0,
                                    "y": 0,
                                    "rotation": 0,
                                    "textValue": "Service Description",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "jyfT",
                                "x": 200,
                                "y": 100,
                                "width": 156.138671875,
                                "height": 31,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "jyfT",
                                    "type": "TextCell",
                                    "x": 601,
                                    "y": 0,
                                    "rotation": 0,
                                    "textValue": "Cost",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "qwbm",
                                "x": 100,
                                "y": 150,
                                "width": 601,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "left",
                                "type": "TableCell",
                                "content": {
                                    "id": "qwbm",
                                    "x": 0,
                                    "y": 31,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "C5Ep",
                                "x": 200,
                                "y": 150,
                                "width": 156.138671875,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "C5Ep",
                                    "x": 601,
                                    "y": 31,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "pJ42",
                                "x": 100,
                                "y": 150,
                                "width": 601,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "left",
                                "type": "TableCell",
                                "content": {
                                    "id": "pJ42",
                                    "x": 0,
                                    "y": 122,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "votn",
                                "x": 200,
                                "y": 150,
                                "width": 156.138671875,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "votn",
                                    "x": 601,
                                    "y": 122,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "21FD",
                                "x": 100,
                                "y": 150,
                                "width": 601,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "left",
                                "type": "TableCell",
                                "content": {
                                    "id": "21FD",
                                    "x": 0,
                                    "y": 213,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "KRNt",
                                "x": 200,
                                "y": 150,
                                "width": 156.138671875,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "KRNt",
                                    "x": 601,
                                    "y": 213,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "urmm",
                                "x": 100,
                                "y": 150,
                                "width": 601,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "left",
                                "type": "TableCell",
                                "content": {
                                    "id": "urmm",
                                    "x": 0,
                                    "y": 304,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "pGI6",
                                "x": 200,
                                "y": 150,
                                "width": 156.138671875,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "pGI6",
                                    "x": 601,
                                    "y": 304,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "Ce4C",
                                "x": 100,
                                "y": 150,
                                "width": 601,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "left",
                                "type": "TableCell",
                                "content": {
                                    "id": "Ce4C",
                                    "x": 0,
                                    "y": 395,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "5UPJ",
                                "x": 200,
                                "y": 150,
                                "width": 156.138671875,
                                "height": 91,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "5UPJ",
                                    "x": 601,
                                    "y": 395,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "en4h",
                    "type": "Rectangle",
                    "x": 28,
                    "y": 785,
                    "rotation": 0,
                    "width": 754,
                    "height": 59,
                    "fill": "#bfbfbf",
                    "stroke": "",
                    "strokeWidth": 1
                },
                {
                    "id": "95Kb",
                    "type": "Heading",
                    "x": 42,
                    "y": 801,
                    "rotation": 0,
                    "textValue": "TOTAL MATERIALS & LABOR",
                    "fontSize": 28,
                    "fill": "#ffffff",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "el2w",
                    "type": "ShortTextInput",
                    "name": "Total Cost",
                    "x": 634,
                    "y": 801,
                    "rotation": 0,
                    "fontSize": 28,
                    "fill": "#ffffff",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "textValue": "",
                    "horizontalAlign": "center",
                    "width": 142,
                    "height": 28
                },
                {
                    "id": "yNBE",
                    "type": "UserImage",
                    "x": 25,
                    "y": 25,
                    "rotation": 0,
                    "src": "https://bryxstorage.blob.core.windows.net/assets/bryx_logo.png",
                    "width": 80,
                    "height": 80
                }
            ],
            "inputOrder": [
                "VNLq",
                "wUD6",
                "GutW",
                "TFIW",
                "aULg",
                "el2w",
                "Cdz2"
            ],
            "pageWidth": 8.5,
            "pageHeight": 11
        }
    },
    {
        name: "Packages Estimate",
        "canvasDesign": {
            "Shapes": [
                {
                    "id": "9gbG",
                    "type": "RoundedRectangle",
                    "x": 24,
                    "y": 240,
                    "rotation": 0,
                    "width": 751,
                    "height": 195,
                    "fill": "transparent",
                    "stroke": "#000000",
                    "strokeWidth": 3,
                    "cornerRadius": 20
                },
                {
                    "id": "iycU",
                    "type": "Heading",
                    "x": 539,
                    "y": 55,
                    "rotation": 0,
                    "textValue": "LICENSE #XXXXXX",
                    "fontSize": 28,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "y3NL",
                    "type": "Heading",
                    "x": 375,
                    "y": 107,
                    "rotation": 0,
                    "textValue": "Client",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "VNLq",
                    "type": "ShortTextInput",
                    "name": "Client Name",
                    "x": 445,
                    "y": 107,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "John Doe",
                    "horizontalAlign": "left",
                    "width": 331,
                    "height": 20,
                },
                {
                    "id": "X6FS",
                    "type": "Heading",
                    "x": 354,
                    "y": 137,
                    "rotation": 0,
                    "textValue": "Address",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "wUD6",
                    "type": "ShortTextInput",
                    "name": "Client Address",
                    "x": 445,
                    "y": 137,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "1234 W 1st St Los Angeles, CA",
                    "horizontalAlign": "left",
                    "width": 332,
                    "height": 20,
                },
                {
                    "id": "XsXu",
                    "type": "Heading",
                    "x": 369,
                    "y": 167,
                    "rotation": 0,
                    "textValue": "Phone",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "GutW",
                    "type": "PhoneInput",
                    "name": "Phone Number",
                    "x": 446,
                    "y": 166,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "(555)555-5555",
                    "horizontalAlign": "left",
                    "width": 160,
                    "height": 20,
                },
                {
                    "id": "TFIW",
                    "type": "EmailInput",
                    "name": "Email Address",
                    "x": 446,
                    "y": 196,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "john.doe@email.com",
                    "horizontalAlign": "left",
                    "width": 334,
                    "height": 20,
                },
                {
                    "id": "QAI8",
                    "type": "Heading",
                    "x": 376,
                    "y": 196,
                    "rotation": 0,
                    "textValue": "Email",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "jv0z",
                    "type": "UserImage",
                    "x": 25,
                    "y": 25,
                    "rotation": 0,
                    "src": "https://bryxstorage.blob.core.windows.net/assets/bryx_logo.png",
                    "width": 80,
                    "height": 80
                },
                {
                    "id": "PhkT",
                    "type": "Heading",
                    "x": 25,
                    "y": 137,
                    "rotation": 0,
                    "textValue": "Licensed Bonded Insured",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": ""
                },
                {
                    "id": "kBEX",
                    "type": "Heading",
                    "x": 23,
                    "y": 166,
                    "rotation": 0,
                    "textValue": "(623)469-5513",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "cEAh",
                    "type": "Heading",
                    "x": 25,
                    "y": 196,
                    "rotation": 0,
                    "textValue": "johndoe@mybizemail.com",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "TjCD",
                    "type": "Paragraph",
                    "x": 34,
                    "y": 282,
                    "rotation": 0,
                    "textValue": "SITE PREPARATION - Base of area and workspaces are cleared of all debris\nCLEAN - Work area is cleaned to ensure a safe and efficient work environment.\nPROTECTION - Mask & tape all areas not to be affected, windows + fixtures. \nREPAIR - Minor repairs within scope of work. \nWORK AREA - Ensure proper preparation of work area. \nMATERIALS - Use of high-quality materials and tools. \nPERMITS - Expedite necessary permits if applicable.\nWARRANTY - 2 Year Limited Warranty",
                    "fontSize": 18,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "horizontalAlign": "left"
                },
                {
                    "id": "PLOJ",
                    "type": "Heading",
                    "x": 34,
                    "y": 251,
                    "rotation": 0,
                    "textValue": "INCLUDED IN ALL PACKAGES",
                    "fontSize": 24,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "rZu9",
                    "type": "Heading",
                    "x": 34,
                    "y": 451,
                    "rotation": 0,
                    "textValue": "PACKAGES",
                    "fontSize": 24,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "0WFV",
                    "type": "RoundedRectangle",
                    "x": 540,
                    "y": 485,
                    "rotation": 0,
                    "width": 238,
                    "height": 328,
                    "fill": "#FC4C02",
                    "stroke": "",
                    "strokeWidth": 1,
                    "cornerRadius": 20
                },
                {
                    "id": "eRiz",
                    "type": "RoundedRectangle",
                    "x": 288,
                    "y": 485,
                    "rotation": 0,
                    "width": 238,
                    "height": 328,
                    "fill": "#005778",
                    "stroke": "",
                    "strokeWidth": 1,
                    "cornerRadius": 20
                },
                {
                    "id": "3Sm9",
                    "type": "RoundedRectangle",
                    "x": 32,
                    "y": 485,
                    "rotation": 0,
                    "width": 238,
                    "height": 328,
                    "fill": "#008E97",
                    "stroke": "",
                    "strokeWidth": 1,
                    "cornerRadius": 20
                },
                {
                    "id": "bWEj",
                    "type": "RoundedRectangle",
                    "x": 33,
                    "y": 825,
                    "rotation": 0,
                    "width": 750,
                    "height": 205,
                    "fill": "transparent",
                    "stroke": "#000000",
                    "strokeWidth": 3,
                    "cornerRadius": 20
                },
                {
                    "id": "aMrS",
                    "type": "Heading",
                    "x": 44,
                    "y": 834,
                    "rotation": 0,
                    "textValue": "Notes:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "Cdz2",
                    "type": "LongTextInput",
                    "name": "Notes",
                    "x": 44,
                    "y": 860,
                    "rotation": 0,
                    "verticalAlign": "top",
                    "fontSize": 18,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "",
                    "horizontalAlign": "left",
                    "width": 729,
                    "height": 157,
                },
                {
                    "id": "LbwH",
                    "type": "Heading",
                    "x": 100,
                    "y": 495,
                    "rotation": 0,
                    "textValue": "DELUXE",
                    "fontSize": 24,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "K9KX",
                    "type": "Heading",
                    "x": 349,
                    "y": 495,
                    "rotation": 0,
                    "textValue": "ULTIMATE",
                    "fontSize": 24,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "IUxP",
                    "type": "Heading",
                    "x": 601,
                    "y": 495,
                    "rotation": 0,
                    "textValue": "MAXIMUM",
                    "fontSize": 24,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "LLbh",
                    "type": "Paragraph",
                    "x": 38,
                    "y": 550,
                    "rotation": 0,
                    "textValue": "Standard Materials\n\n\n\nExpected Life Span: \n3-5 Years\n\n\n\nTotal Materials & Labor:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "horizontalAlign": "center"
                },
                {
                    "id": "POVQ",
                    "type": "Paragraph",
                    "x": 293,
                    "y": 550,
                    "rotation": 0,
                    "textValue": "Intermediate Materials\n\n\n\nExpected Life Span: \n5-7 Years\n\n\n\nTotal Materials & Labor:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "horizontalAlign": "center"
                },
                {
                    "id": "T3OU",
                    "type": "Paragraph",
                    "x": 547,
                    "y": 550,
                    "rotation": 0,
                    "textValue": "Premium Materials\n\n\n\nExpected Life Span: \n7-10 Years\n\n\n\nTotal Materials & Labor:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "horizontalAlign": "center"
                },
                {
                    "id": "mYUa",
                    "type": "ShortTextInput",
                    "name": "Ultimate Price",
                    "x": 299,
                    "y": 768,
                    "rotation": 0,
                    "fontSize": 28,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "textValue": "$0.00",
                    "horizontalAlign": "center",
                    "width": 219,
                    "height": 28,
                },
                {
                    "id": "9oOp",
                    "type": "ShortTextInput",
                    "name": "Deluxe Price",
                    "x": 42,
                    "y": 768,
                    "rotation": 0,
                    "fontSize": 28,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "textValue": "$0.00",
                    "horizontalAlign": "center",
                    "width": 219,
                    "height": 28,
                },
                {
                    "id": "zjou",
                    "type": "ShortTextInput",
                    "name": "Maximum Price",
                    "x": 551,
                    "y": 768,
                    "rotation": 0,
                    "fontSize": 28,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "textValue": "$0.00",
                    "horizontalAlign": "center",
                    "width": 218,
                    "height": 28,
                }
            ],
            "inputOrder": [
                "VNLq",
                "wUD6",
                "GutW",
                "TFIW",
                "9oOp",
                "mYUa",
                "zjou",
                "Cdz2"
            ],
            "pageWidth": 8.5,
            "pageHeight": 11
        },
    },
    {
        name: "Breakdown Estimate",
        "canvasDesign": {
            "Shapes": [
                {
                    "id": "ucz5",
                    "type": "RoundedRectangle",
                    "x": 381,
                    "y": 688,
                    "rotation": 0,
                    "width": 406,
                    "height": 297,
                    "fill": "#1abce2",
                    "stroke": "",
                    "strokeWidth": 1,
                    "cornerRadius": 20
                },
                {
                    "id": "xNMd",
                    "type": "Rectangle",
                    "x": -13,
                    "y": -23,
                    "rotation": 0,
                    "width": 836,
                    "height": 100,
                    "fill": "#1abce2",
                    "stroke": "",
                    "strokeWidth": 1
                },
                {
                    "id": "dgr1",
                    "type": "Heading",
                    "x": 209,
                    "y": 21,
                    "rotation": 0,
                    "textValue": "Cleaning Proposal",
                    "fontSize": 36,
                    "fill": "#ffffff",
                    "fontFamily": "Verdana",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "31xD",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 22,
                    "y": 90,
                    "rotation": 0,
                    "border": {
                        "width": 1,
                        "color": "black"
                    },
                    "rows": [
                        [
                            {
                                "id": "0Ynd",
                                "x": 100,
                                "y": 100,
                                "width": 238.00097028629125,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0Ynd",
                                    "x": 0,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "CellInput",
                                    "rotation": 0,
                                    "fill": "black",
                                    "textValue": "Room Name"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "i727",
                                "x": 200,
                                "y": 100,
                                "width": 94.99996484469955,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "i727",
                                    "x": 238.00097028629125,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Price",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "A4ME",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "A4ME",
                                    "x": 0,
                                    "y": 34.99991850375201,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Vacuuming",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "zB4s",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "zB4s",
                                    "x": 238.00097028629125,
                                    "y": 34.99991850375201,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "gaB1",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "gaB1",
                                    "x": 0,
                                    "y": 66.99982070825442,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Dusting",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "s6Lr",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "s6Lr",
                                    "x": 238.00097028629125,
                                    "y": 66.99982070825442,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "0vey",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0vey",
                                    "x": 0,
                                    "y": 98.99972291275682,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Kitchen + Laundry",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "hoQB",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "hoQB",
                                    "x": 238.00097028629125,
                                    "y": 98.99972291275682,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "ghX6",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "ghX6",
                                    "x": 0,
                                    "y": 130.99962511725923,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Bathroom + Shower",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "WeMP",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "WeMP",
                                    "x": 238.00097028629125,
                                    "y": 130.99962511725923,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "GJBC",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "GJBC",
                                    "x": 0,
                                    "y": 162.99952732176163,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Doors + Window Frame",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "oeds",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "oeds",
                                    "x": 238.00097028629125,
                                    "y": 162.99952732176163,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "uc09",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "uc09",
                                    "x": 0,
                                    "y": 194.99942952626404,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Handles + Railings",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "u0QQ",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "u0QQ",
                                    "x": 238.00097028629125,
                                    "y": 194.99942952626404,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "Eh6f",
                    "x": 120,
                    "y": 120,
                    "width": 238.00097028629125,
                    "height": 34.99991850375201,
                    "verticalAlign": "middle",
                    "horizontalAlign": "center",
                    "type": "TableCell",
                    "content": {
                        "id": "0Ynd",
                        "x": 0,
                        "y": 0,
                        "fontSize": 20,
                        "fontFamily": "Arial",
                        "fontStyle": "bold",
                        "textDecoration": "none",
                        "type": "CellInput",
                        "rotation": 0,
                        "fill": "black",
                        "textValue": "Room Name"
                    },
                    "rotation": 0
                },
                {
                    "id": "0gjL",
                    "type": "Heading",
                    "x": 380,
                    "y": 98,
                    "rotation": 0,
                    "textValue": "Name:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "FFfV",
                    "type": "ShortTextInput",
                    "name": "Client Name",
                    "x": 467,
                    "y": 98,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "John Doe",
                    "horizontalAlign": "left",
                    "width": 316,
                    "height": 20
                },
                {
                    "id": "Ifbz",
                    "type": "Heading",
                    "x": 379,
                    "y": 126,
                    "rotation": 0,
                    "textValue": "Address:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "Q4t8",
                    "type": "ShortTextInput",
                    "name": "Client Address",
                    "x": 467,
                    "y": 126,
                    "rotation": 0,
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "",
                    "horizontalAlign": "left",
                    "width": 316,
                    "height": 20
                },
                {
                    "id": "nxqw",
                    "type": "Heading",
                    "x": 379,
                    "y": 157,
                    "rotation": 0,
                    "textValue": "Description of Cleaning Job:",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": ""
                },
                {
                    "id": "LFCi",
                    "type": "LongTextInput",
                    "name": "Job Description",
                    "x": 379,
                    "y": 184,
                    "rotation": 0,
                    "verticalAlign": "top",
                    "fontSize": 20,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "textValue": "",
                    "horizontalAlign": "left",
                    "width": 403,
                    "height": 128
                },
                {
                    "id": "TFSp",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 380,
                    "y": 331,
                    "rotation": 0,
                    "border": {
                        "width": 1,
                        "color": "black"
                    },
                    "rows": [
                        [
                            {
                                "id": "UeKN",
                                "x": 100,
                                "y": 100,
                                "width": 302.00142027413654,
                                "height": 29.999891338336,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "UeKN",
                                    "type": "TextCell",
                                    "x": 0,
                                    "y": 0,
                                    "rotation": 0,
                                    "textValue": "Other Additional Items:",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "",
                                    "textDecoration": "none"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "PRtD",
                                "x": 200,
                                "y": 100,
                                "width": 100,
                                "height": 29.999891338336,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "PRtD",
                                    "type": "TextCell",
                                    "x": 302.00142027413654,
                                    "y": 0,
                                    "rotation": 0,
                                    "textValue": "Price",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "5tMe",
                                "x": 100,
                                "y": 150,
                                "width": 302.00142027413654,
                                "height": 139.0004835444048,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "5tMe",
                                    "x": 0,
                                    "y": 29.999891338336,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "1HIa",
                                "x": 200,
                                "y": 150,
                                "width": 100,
                                "height": 139.0004835444048,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "1HIa",
                                    "x": 302.00142027413654,
                                    "y": 29.999891338336,
                                    "textValue": "$0.00",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "f19I",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 23,
                    "y": 332,
                    "rotation": 0,
                    "border": {
                        "width": 1,
                        "color": "black"
                    },
                    "rows": [
                        [
                            {
                                "id": "0Ynd",
                                "x": 100,
                                "y": 100,
                                "width": 238.00097028629125,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0Ynd",
                                    "x": 0,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "CellInput",
                                    "rotation": 0,
                                    "fill": "black",
                                    "textValue": "Room Name"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "i727",
                                "x": 200,
                                "y": 100,
                                "width": 94.99996484469955,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "i727",
                                    "x": 238.00097028629125,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Price",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "A4ME",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "A4ME",
                                    "x": 0,
                                    "y": 34.99991850375201,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Vacuuming",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "zB4s",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "zB4s",
                                    "x": 238.00097028629125,
                                    "y": 34.99991850375201,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "gaB1",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "gaB1",
                                    "x": 0,
                                    "y": 66.99982070825442,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Dusting",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "s6Lr",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "s6Lr",
                                    "x": 238.00097028629125,
                                    "y": 66.99982070825442,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "0vey",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0vey",
                                    "x": 0,
                                    "y": 98.99972291275682,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Kitchen + Laundry",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "hoQB",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "hoQB",
                                    "x": 238.00097028629125,
                                    "y": 98.99972291275682,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "ghX6",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "ghX6",
                                    "x": 0,
                                    "y": 130.99962511725923,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Bathroom + Shower",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "WeMP",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "WeMP",
                                    "x": 238.00097028629125,
                                    "y": 130.99962511725923,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "GJBC",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "GJBC",
                                    "x": 0,
                                    "y": 162.99952732176163,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Doors + Window Frame",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "oeds",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "oeds",
                                    "x": 238.00097028629125,
                                    "y": 162.99952732176163,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "uc09",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "uc09",
                                    "x": 0,
                                    "y": 194.99942952626404,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Handles + Railings",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "u0QQ",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "u0QQ",
                                    "x": 238.00097028629125,
                                    "y": 194.99942952626404,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "9ftY",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 24,
                    "y": 572,
                    "rotation": 0,
                    "border": {
                        "width": 1,
                        "color": "black"
                    },
                    "rows": [
                        [
                            {
                                "id": "0Ynd",
                                "x": 100,
                                "y": 100,
                                "width": 238.00097028629125,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0Ynd",
                                    "x": 0,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "CellInput",
                                    "rotation": 0,
                                    "fill": "black",
                                    "textValue": "Room Name"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "i727",
                                "x": 200,
                                "y": 100,
                                "width": 94.99996484469955,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "i727",
                                    "x": 238.00097028629125,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Price",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "A4ME",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "A4ME",
                                    "x": 0,
                                    "y": 34.99991850375201,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Vacuuming",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "zB4s",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "zB4s",
                                    "x": 238.00097028629125,
                                    "y": 34.99991850375201,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "gaB1",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "gaB1",
                                    "x": 0,
                                    "y": 66.99982070825442,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Dusting",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "s6Lr",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "s6Lr",
                                    "x": 238.00097028629125,
                                    "y": 66.99982070825442,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "0vey",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0vey",
                                    "x": 0,
                                    "y": 98.99972291275682,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Kitchen + Laundry",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "hoQB",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "hoQB",
                                    "x": 238.00097028629125,
                                    "y": 98.99972291275682,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "ghX6",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "ghX6",
                                    "x": 0,
                                    "y": 130.99962511725923,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Bathroom + Shower",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "WeMP",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "WeMP",
                                    "x": 238.00097028629125,
                                    "y": 130.99962511725923,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "GJBC",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "GJBC",
                                    "x": 0,
                                    "y": 162.99952732176163,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Doors + Window Frame",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "oeds",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "oeds",
                                    "x": 238.00097028629125,
                                    "y": 162.99952732176163,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "uc09",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "uc09",
                                    "x": 0,
                                    "y": 194.99942952626404,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Handles + Railings",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "u0QQ",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "u0QQ",
                                    "x": 238.00097028629125,
                                    "y": 194.99942952626404,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "k6di",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 24,
                    "y": 812,
                    "rotation": 0,
                    "border": {
                        "width": 1,
                        "color": "black"
                    },
                    "rows": [
                        [
                            {
                                "id": "0Ynd",
                                "x": 100,
                                "y": 100,
                                "width": 238.00097028629125,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0Ynd",
                                    "x": 0,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "CellInput",
                                    "rotation": 0,
                                    "fill": "black",
                                    "textValue": "Room Name"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "i727",
                                "x": 200,
                                "y": 100,
                                "width": 94.99996484469955,
                                "height": 34.99991850375201,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "i727",
                                    "x": 238.00097028629125,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Price",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "A4ME",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "A4ME",
                                    "x": 0,
                                    "y": 34.99991850375201,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Vacuuming",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "zB4s",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "zB4s",
                                    "x": 238.00097028629125,
                                    "y": 34.99991850375201,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "gaB1",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "gaB1",
                                    "x": 0,
                                    "y": 66.99982070825442,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Dusting",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "s6Lr",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "s6Lr",
                                    "x": 238.00097028629125,
                                    "y": 66.99982070825442,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "0vey",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "0vey",
                                    "x": 0,
                                    "y": 98.99972291275682,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Kitchen + Laundry",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "hoQB",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "hoQB",
                                    "x": 238.00097028629125,
                                    "y": 98.99972291275682,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "ghX6",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "ghX6",
                                    "x": 0,
                                    "y": 130.99962511725923,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Bathroom + Shower",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "WeMP",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "WeMP",
                                    "x": 238.00097028629125,
                                    "y": 130.99962511725923,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "GJBC",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "GJBC",
                                    "x": 0,
                                    "y": 162.99952732176163,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Doors + Window Frame",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "oeds",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "oeds",
                                    "x": 238.00097028629125,
                                    "y": 162.99952732176163,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "uc09",
                                "x": 100,
                                "y": 150,
                                "width": 238.00097028629125,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "uc09",
                                    "x": 0,
                                    "y": 194.99942952626404,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Handles + Railings",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "u0QQ",
                                "x": 200,
                                "y": 150,
                                "width": 94.99996484469955,
                                "height": 31.999902204502405,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "u0QQ",
                                    "x": 238.00097028629125,
                                    "y": 194.99942952626404,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "gxML",
                    "type": "Paragraph",
                    "x": 398,
                    "y": 999,
                    "rotation": 0,
                    "textValue": "We accept cash, checks, and all major credit cards \nfor your convenience.",
                    "fontSize": 16,
                    "fill": "black",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "textDecoration": "",
                    "horizontalAlign": "center"
                },
                {
                    "id": "sdBF",
                    "name": "Table",
                    "type": "TableInput",
                    "x": 381,
                    "y": 523,
                    "rotation": 0,
                    "border": {
                        "width": 1,
                        "color": "black"
                    },
                    "rows": [
                        [
                            {
                                "id": "Rq96",
                                "x": 100,
                                "y": 100,
                                "width": 152,
                                "height": 50,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "Rq96",
                                    "type": "TextCell",
                                    "x": 0,
                                    "y": 0,
                                    "rotation": 0,
                                    "textValue": "Subtotal",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "bold",
                                    "textDecoration": "none"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "E5OG",
                                "x": 200,
                                "y": 100,
                                "width": 247,
                                "height": 50,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "E5OG",
                                    "x": 152,
                                    "y": 0,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "",
                                    "textDecoration": "none",
                                    "type": "CellInput",
                                    "rotation": 0,
                                    "fill": "black",
                                    "textValue": ""
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "EK4o",
                                "x": 100,
                                "y": 150,
                                "width": 152,
                                "height": 50,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "EK4o",
                                    "x": 0,
                                    "y": 50,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal bold",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Tax",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "3gb7",
                                "x": 200,
                                "y": 150,
                                "width": 247,
                                "height": 50,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "3gb7",
                                    "x": 152,
                                    "y": 50,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ],
                        [
                            {
                                "id": "nLDs",
                                "x": 100,
                                "y": 200,
                                "width": 152,
                                "height": 50,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "nLDs",
                                    "x": 0,
                                    "y": 100,
                                    "fontSize": 20,
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal bold",
                                    "textDecoration": "none",
                                    "type": "TextCell",
                                    "textValue": "Total",
                                    "rotation": 0,
                                    "fill": "black"
                                },
                                "rotation": 0
                            },
                            {
                                "id": "uWG0",
                                "x": 200,
                                "y": 200,
                                "width": 247,
                                "height": 50,
                                "verticalAlign": "middle",
                                "horizontalAlign": "center",
                                "type": "TableCell",
                                "content": {
                                    "id": "uWG0",
                                    "x": 152,
                                    "y": 100,
                                    "textValue": "",
                                    "fontSize": 20,
                                    "fill": "black",
                                    "fontFamily": "Arial",
                                    "fontStyle": "normal",
                                    "textDecoration": "none",
                                    "rotation": 0,
                                    "type": "CellInput"
                                },
                                "rotation": 0
                            }
                        ]
                    ]
                },
                {
                    "id": "uc2B",
                    "type": "Heading",
                    "x": 390,
                    "y": 710,
                    "rotation": 0,
                    "textValue": "Sparkle & Shine Cleaners",
                    "fontSize": 32,
                    "fill": "#ffffff",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "lEhN",
                    "type": "Heading",
                    "x": 391,
                    "y": 945,
                    "rotation": 0,
                    "textValue": "Sparkle@ShineCleaners.com",
                    "fontSize": 28,
                    "fill": "#ffffff",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "soGZ",
                    "type": "Heading",
                    "x": 489,
                    "y": 900,
                    "rotation": 0,
                    "textValue": "(555)555-5555",
                    "fontSize": 28,
                    "fill": "#ffffff",
                    "fontFamily": "Arial",
                    "fontStyle": "bold",
                    "textDecoration": ""
                },
                {
                    "id": "Tvb9",
                    "type": "Paragraph",
                    "x": 457,
                    "y": 791,
                    "rotation": 0,
                    "textValue": "Contact us with an\nquestions",
                    "fontSize": 28,
                    "fill": "#ffffff",
                    "fontFamily": "Arial",
                    "fontStyle": "normal bold",
                    "textDecoration": "",
                    "horizontalAlign": "center"
                }
            ],
            "inputOrder": [
                "FFfV",
                "Q4t8",
                "LFCi",
                "31xD",
                "f19I",
                "9ftY",
                "TFSp",
                "k6di",
                "sdBF"
            ],
            "pageWidth": 8.5,
            "pageHeight": 11
        }
    }
]